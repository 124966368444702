import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import style from "./style.module.scss";
import Button from "../../../components/atoms/Button/Button";
import { toast } from "react-toastify";
import { IAuthActivateAccount } from "types/auth/auth.type";
import { AuthActivateAccount } from "store/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import theme from "../../../assets/themes";
import { InputField, Loading } from "components";
import { useChameleonIdentify } from "utils/hooks/useChameleonIdentify";

const SignupConfirmAccount: React.FC = () => {
  const [searchParams] = useSearchParams();
  const gatewayCustomerId = searchParams.get("cid")
  const { register, handleSubmit, formState: { errors }, } = useForm<IAuthActivateAccount>();
  const { isLoading, successMessage, errorMessage } = useAppSelector((state) => state.auth.signUp);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoadingInit, setIsLoadingInit] = useState(true);
  const handleSignUp = (data: IAuthActivateAccount) => {
    if (data.password !== data.passwordConfirm) {
      toast.error("Passwords do not match");
      return
    }
    dispatch(AuthActivateAccount({
      password: data.password,
      gatewayCustomerId: gatewayCustomerId as string
    }));
  };
  useEffect(() => {
    if (!gatewayCustomerId) navigate("/");
    if (localStorage.getItem("accessToken")) navigate("/dashboard");
    setTimeout(() => { setIsLoadingInit(false); }, 2700);
    window.analytics.page("Sign Up Confirmation")
  }, []);

  useChameleonIdentify();

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      navigate("/");
    }
    if (errorMessage) toast.error(String(errorMessage));
  }, [successMessage, errorMessage]);
  useEffect(() => {
    const container = document.getElementById("bg-container");
    if (!container) return
    container.style.backgroundImage = `url(${theme["bgImage"]})`;
  }, [isLoading]);
  return <div className={`${style.signup_container} sm:h-screen h-auto`} id="bg-container">
    {isLoadingInit
    ? <Loading background="transparent" />
    : <>
      <div className={style.logo}>
        <img className={style.img} src={theme["logo"]} alt="logo" onClick={() => navigate("/welcome")} />
      </div>
      <div className={`${style.container_wrapper} bg-paper-500`}>
        <div className={style.form_wrapper_up}>
          <form onSubmit={handleSubmit(handleSignUp)} className={style.form}>
            <h1 className="text-2xl text-center font-bold">🚀 Welcome aboard! Let's finish setting up your account.</h1>
            <p className="text-center"><span className="text-base text-gray-300">Please provide a password so you can sign in to your account safely.</span></p>
            <div className={style.inputs}>
              <div className="flex gap-3 flex-col w-full">
                <label className="font-bold">Create your password</label>
                <InputField
                  iconName="lock" type="password" name="password" errors={errors} register={register} maxLength={24}
                  validationSchema={{
                    required: "Password is required",
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
                      message: "Minimum eight characters, at least one upper case English letter, one lower case English letter, one number and one special character",
                  }}}
                />
              </div>
              <div className="flex gap-3 flex-col w-full">
                <label className="font-bold">Confirm your password</label>
                <InputField iconName="lock" type="password" name="passwordConfirm" errors={errors} register={register} maxLength={24} />
              </div>
            </div>
            <p className="text-sm text-center text-gray-300">
              By continuing, you agree with our{" "}
              <Link to={"/terms-of-service"} target="_blank" className="link">Terms of Service</Link>
            </p>
            <Button
              type="secondary" action="submit" size="lg" className="w-full" iconName="user-check"
              isProcessing={isLoading} isProcessingTitle="Activating account"
            >Activate Account</Button>
            <div className="items-center flex flex-col">
              <p className="text-sm text-gray-300 mb-3">Already a member?</p>
              <Button size="sm" action="button" onClick={() => navigate("/signin")}>Sign In</Button>
            </div>
          </form>
        </div>
      </div>
    </>}
  </div>
};
export default SignupConfirmAccount
