const constants = {
  PIXEL_KEYWORD: "pulse",
  LEADSEARCH_KEYWORD: "reveal",

  FREE_TRIAL_DAYS: 7,

  SINGLE_LEAD_ENRICHMENT_CREDIT_COST_DEFAULT: 1,
  SINGLE_LEAD_ENRICHMENT_CREDIT_COST_QUALIGENCE: 0.5, // cents

  LIMIT_ENRICHMENT_ATTEMPT_PER_TASK: 10000,
  
  CREDITS_PURCHASE_LIMIT_MAXIMUM: 25000,
  CREDITS_PURCHASE_LIMIT_MINIMUM: 50,
  REVENUE_INSTITUTE_DOMAIN: "revenueinstitute.com",

  COMPANY_1_EMAIL_SUPPORT: "support@identitymatrix.ai",

  IDENTITY_MATRIX_INTERCOM_KEY: "cogdu9io",
  QUALIGENCE_INTERCOM_KEY: "xyuoksxk",
}
export default constants;
